import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import customFetch, { API_URL } from "../utils/fetch";

export default function User({ userId }) {
  const [user, setUser] = useState([]);
  const { handleSubmit, register, errors } = useForm();

  function fetchSubscriptions() {
    return customFetch(`${API_URL}/admin/user/${userId}`).then((data) => {
      setUser(data?.user);
    });
  }

  useEffect(() => {
    // Fetch subscriptions with payments for a payer ID
    fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmit({ password }) {
    await customFetch(`${API_URL}/admin/changepassword`, {
      method: "POST",
      body: JSON.stringify({
        email: user.email,
        password: password,
      }),
    });
    toast.success(`Updated password for ${user.email}`);
  }

  function ErrorText({ children }) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="text-red-400 text-sm pl-2 mb-3 flex-wrap w-48"
      >
        {children}
      </motion.div>
    );
  }

  return (
    <div className="w-full flex flex-col bg-white rounded-lg pb-24">
      <div className={`p-4 py-6 flex flex-col`}>
        <div>
          <h2 className={`text-xl`}>User</h2>
          <div>{user?.email}</div>
          <div className="text-xs">{user?.id}</div>
        </div>
        <div></div>
      </div>
      <div className={`p-4 py-6 flex flex-col`}>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Update {user?.email}'s password
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>Change the password for this user</p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="mt-5 sm:flex sm:items-end"
            >
              <div className="w-full sm:max-w-xs">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: "Password is required",
                        min: 10,
                      })}
                      type="text"
                      name="password"
                      id="password"
                      className="shadow-sm border p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
            </form>
            {errors.password && (
              <ErrorText>{errors.password.message}</ErrorText>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
