import React, { useEffect } from "react";
import "./assets/main.css";
import { useRoutes } from "hookrouter";
import Checkout from "./Checkout";
import AdminRouter from "./admin";
import Login from "./login";
import LeaderPortal from "./LeaderPortal";
import LeaderCheckout from "./LeaderCheckout";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const overrideMaintanance = urlParams.get("override_maintanance_mode");

let downForMaintenance = true;

if (!!overrideMaintanance) {
  downForMaintenance = false;
}

export function Input({ passedRef, hasError, className, ...rest }) {
  return (
    <input
      className={`rounded-lg h-12 p-2 px-3 w-full placeholder-gray-400 ${className} ${
        hasError ? "" : "mb-4"
      }`}
      ref={passedRef}
      {...rest}
    />
  );
}

let orderId;

function setOrderId(ordId) {
  orderId = ordId;
}

function Success() {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.href) || {};
    const serializedParams = {};
    //Iterate the search parameters.
    // @ts-ignore
    // eslint-disable-next-line
    for (let p of searchParams) {
      serializedParams[p[0]] = p[1];
    }

    const resp = {
      message: "worldpay-transaction",
      serializedParams,
    };

    window.parent.postMessage(JSON.stringify(resp), "*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen text-5xl"></div>
  );
}

let routes = {
  "/": () => ({ setOrderId }) => (
    <div className="">Please use the link sent to you by email!</div>
  ),
  "/login": () => () => <Login />,
  "/app*": () => () => <AdminRouter />,
  "/checkout": () => ({ setOrderId }) => <Checkout setOrderId={setOrderId} />,
  "/checkout/:troopNumber": ({ troopNumber }) => ({ setOrderId }) => (
    <Checkout setOrderId={setOrderId} troopNumber={troopNumber} />
  ),
  "/checkout/leader/:troopNumber": ({ troopNumber }) => ({ setOrderId }) => (
    <LeaderCheckout setOrderId={setOrderId} troopNumber={troopNumber} />
  ),
  "/success": () => ({ orderId }) => <Success orderId={orderId} />,
  "/leader/portal": () => () => <LeaderPortal />,
};

if (downForMaintenance) {
  routes = {
    "/app*": () => () => <AdminRouter />,
    "/login": () => () => <Login />,
    "/*": () => () => {
      return (
        <div className="flex items-center justify-center w-screen h-screen bg-gray-200">
          <h1 className="text-xl font-semibold">
            We are down for maintenance. Sorry for the inconvenience!
          </h1>
        </div>
      );
    },
  };
}

function App() {
  const routeResult = useRoutes(routes);

  return (
    <div className="h-full">
      <div className="flex justify-center w-full h-full mx-auto">
        {routeResult({ orderId, setOrderId }) || <div>Nothing Found</div>}
      </div>
    </div>
  );
}

export default App;
