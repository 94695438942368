import React, { useState, useEffect } from "react";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { TableRow, Table, TableData, TableHeader } from "./components";
import {
  useTable,
  useExpanded,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { motion } from "framer-motion";
import customFetch, { API_URL } from "../utils/fetch";
import { PROJECT_ID } from "../utils/constants";
import { DateTime } from "luxon";
import { usePopper } from "react-popper";
import { DateRangePicker } from "react-dates";

function GenerateReport() {
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState("START_DATE");
  const [dates, setDates] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  async function handleGenerateRerport() {
    setLoading(true);
    try {
      await customFetch(`${API_URL}/reports/123/transactions`, {
        method: "POST",
        body: JSON.stringify({
          startDate: dates.startDate?.format("L"),
          endDate: dates.endDate?.format("L"),
        }),
      });
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    setShowPopup(false);
  }

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        onClick={() => setShowPopup(!showPopup)}
        className={`h-8 px-4 font-medium text-white rounded-lg mr-4 ${
          showPopup ? "bg-firefly" : "bg-supernova"
        }`}
      >
        Generate Report
      </button>
      {showPopup && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          className="bg-white shadow-lg shadow shadow-xs rounded-lg mb-4 mt-4 p-4 flex flex-col"
          {...attributes.popper}
        >
          <DateRangePicker
            isOutsideRange={() => false}
            startDate={dates?.startDate}
            startDateId="startDate"
            endDate={dates?.endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) =>
              setDates({ startDate, endDate })
            }
            withPortal
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            anchorDirection="ANCHOR_LEFT"
          />
          <button
            onClick={handleGenerateRerport}
            className="h-10 px-4 font-medium text-white rounded-lg mt-4 bg-supernova"
          >
            {loading ? "Generating..." : "Generate"}
          </button>
        </div>
      )}
    </>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  return (
    <div className="flex items-center p-4">
      <div>Search: </div>
      <input
        className="ml-2 p-4 w-full"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

export function Transactions() {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: (row) => row?.id,
      },
      {
        Header: "Type",
        accessor: (row) =>
          !!row?.transactionSetupId
            ? "Upfront Payment"
            : "Subscription Payment",
      },
      {
        Header: "Unit",
        accessor: (row) => {
          return row?.subscription?.Scout?.Unit?.unitId;
        },
      },
      {
        Header: "Unit Type",
        accessor: (row) => {
          return row?.subscription?.Scout?.Unit?.type;
        },
      },
      {
        Header: "Unit Number",
        accessor: (row) => {
          return row?.subscription?.Scout?.Unit?.no;
        },
      },
      {
        Header: "Unit District",
        accessor: (row) => {
          return row?.subscription?.Scout?.Unit?.districtName;
        },
      },
      {
        Header: "Scout",
        accessor: (row) => {
          return `${row?.subscription?.Scout?.firstName} ${row?.subscription?.Scout?.lastName}`;
        },
      },
      {
        Header: "Amount",
        accessor: (row) => {
          if (row.transactionSetupId) {
            return `$${Number.parseFloat(row?.upfrontAmount).toFixed(2)}`;
          }

          return `$${Number.parseFloat(row?.amount).toFixed(2)}`;
        },
      },
      {
        Header: "Paid",
        accessor: (row) => row.updatedAt,
        Cell: (props) => (
          <span>{DateTime.fromISO(props.value).toFormat("DDD")}</span>
        ),
      },
    ],
    []
  );

  const [orders, setOrders] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: orders,
      initialState: { pageIndex: 0, sortBy: [{ id: "Paid", desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    Promise.all([
      customFetch(`${API_URL}/subscriptions/payments/approved`),
      customFetch(`${API_URL}/orders/project/${PROJECT_ID}/closed`),
    ]).then((data) => {
      setOrders([].concat(data[1].orders).concat(data[0].payments));
    });
  }, []);

  function renderRowSubComponent({ original }) {
    const payment = original.subscription.payments[0];
    return (
      <div className="px-12">
        <div className="font-bold pb-2">Next Payment Info</div>
        <div className="flex justify-start">
          <div className="pr-8">
            <div className="text-sm font-bold opacity-75">Amount</div>
            <div>{payment.amount}</div>
          </div>
          <div className="px-8">
            <div className="text-sm font-bold opacity-75">Date</div>
            <div>{new Date(payment.paymentDate).toLocaleString()}</div>
          </div>
          <div className="px-8">
            <div className="text-sm font-bold opacity-75">Status</div>
            <div>{payment.status}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Transaction History</h1>
          <span>Listing of all upfront and subscription transactions made</span>
        </div>
        <div>
          <GenerateReport />
          <button
            className="h-8 px-4 font-medium text-white bg-supernova rounded-lg opacity-75 cursor-not-allowed"
            disabled
          >
            Record Manual Payment
          </button>
        </div>
      </div>
      {orders.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow notHoverable {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    key="globalfilter"
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  // Use a React.Fragment here so the table markup is still valid
                  <React.Fragment {...row.getRowProps()}>
                    <TableRow>
                      {row.cells.map((cell) => {
                        return (
                          <TableData {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableData>
                        );
                      })}
                    </TableRow>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                    {row.isExpanded ? (
                      <TableRow>
                        <TableData colSpan={visibleColumns.length}>
                          {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                          {renderRowSubComponent(row)}
                        </TableData>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className="pagination py-4">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}
