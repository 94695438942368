import React, { useState } from "react";
import { webTokenKey, BASE_API_URL } from "../utils/fetch";
import { navigate, useQueryParams } from "hookrouter";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [queryParams] = useQueryParams();

  function handleLogin(e) {
    e.preventDefault();
    fetch(`${BASE_API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (resp) => {
      if (resp.status === 400) {
        return setError(true);
      }
      const json = await resp.json();
      localStorage.setItem(webTokenKey, json.jwtToken);
      setTimeout(() => {
        const { navTo } = queryParams;
        navigate(navTo ? navTo : "/app/units");
      }, 300);
    });
  }

  return (
    <div className="h-screen flex justify-center items-center">
      <form
        onSubmit={handleLogin}
        className="bg-white shadow-md self-center rounded m-12 px-8 pt-6 pb-8 mb-4 flex flex-col"
      >
        <div>{error && "Your credentials are incorrect"}</div>
        <div className="mb-4">
          <label
            className="block text-grey-darker text-sm font-bold mb-2"
            for="username"
          >
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
            id="username"
            onChange={(e) => setEmail(e?.target?.value)}
            type="text"
            placeholder="Username"
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-grey-darker text-sm font-bold mb-2"
            for="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
            id="password"
            type="password"
            onChange={(e) => setPassword(e?.target?.value)}
            placeholder="******************"
          />
          <p className="text-red text-xs italic">Please choose a password.</p>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
            type="submit"
          >
            Sign In
          </button>
          {/* <a
            className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker"
            href="#"
          >
            Forgot Password?
          </a> */}
        </div>
      </form>
    </div>
  );
}
