import React from "react";
import { Input } from "../App";
import { NavItem } from "./components";
import { useRoutes } from "hookrouter";
import { Troops } from "./troops";
import { Transactions } from "./transactions";
import { Payers } from "./payers";
import { Users } from "./users";
import { AnimatePresence, motion } from "framer-motion";
import {
  Logout,
  CreditCard,
  ClipboardList,
  Users as UsersIcon,
  UserGroup,
} from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./plans";
import Hiking from "../assets/icons/hiking";
import { Unit } from "./unit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payer from "./payer";
import User from "./user";

const routes = {
  "/": () => <Troops />,
  "/units": () => <Troops />,
  "/unit/:unitId": ({ unitId }) => <Unit unitId={unitId} />,
  "/transactions": () => <Transactions />,
  "/payers": () => <Payers />,
  "/payer/:payerId": ({ payerId }) => <Payer payerId={payerId} />,
  "/plans": () => <Plans />,
  "/users": () => <Users />,
  "/user/:userId": ({ userId }) => <User userId={userId} />,
};

export default function Main() {
  const routeResult = useRoutes(routes);

  const contextClass = {
    success: "bg-green-600",
    error: "bg-red-600",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <ToastContainer
        toastClassName={({ type }) =>
          contextClass[type || "default"] +
          " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mb-2"
        }
        bodyClassName={() => "text-sm font-white font-med block p-3"}
      />
      <div className="fixed h-full pr-10">
        <div className="flex flex-col justify-between w-56 h-full text-white rounded-lg bg-firefly">
          <div>
            <div className="flex justify-center p-2 pt-10 pb-20 text-xl">
              Boy Scouts
            </div>
            <div>
              <motion.ul className="flex flex-col">
                <NavItem
                  linkTo="/app/units"
                  selected={routeResult.type === Troops}
                >
                  <Hiking className="h-4 mr-3 fill-current" /> Units
                </NavItem>
                <NavItem
                  linkTo="/app/transactions"
                  selected={routeResult.type === Transactions}
                >
                  <CreditCard className="h-4 mr-2 -mx-1 fill-current" />
                  Transactions
                </NavItem>
                <NavItem
                  linkTo="/app/plans"
                  selected={routeResult.type === Plans}
                >
                  <ClipboardList className="h-4 mr-2 -mx-1 fill-current" />{" "}
                  Plans
                </NavItem>
                <NavItem
                  linkTo="/app/payers"
                  selected={routeResult.type === Payers}
                >
                  <UsersIcon className="h-4 mr-2 -mx-1 fill-current" />
                  Payers
                </NavItem>
                <NavItem
                  linkTo="/app/users"
                  selected={routeResult.type === Users}
                >
                  <UserGroup className="h-4 mr-2 -mx-1 fill-current" />
                  Users
                </NavItem>
              </motion.ul>
            </div>
          </div>
          <div className="p-12 text-center">
            <div
              onClick={logout}
              className="flex justify-center cursor-pointer"
            >
              <Logout className="pr-2" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full pt-4 text-gray-800"
        style={{ marginLeft: "244px" }}
      >
        <Input disabled placeholder="Search Anything Here..." />
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>
      </div>
    </div>
  );
}
