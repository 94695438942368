import React, { useState, useEffect } from "react";

import { TableRow, Table, TableData, TableHeader } from "./components";
import {
  useTable,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ChevronRight, ChevronDown } from "heroicons-react";
import { motion } from "framer-motion";
import customFetch, { API_URL } from "../utils/fetch";
import { navigate } from "hookrouter/dist/router";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  return (
    <div className="flex items-center p-4">
      <div>Search: </div>
      <input
        className="ml-2 p-4 w-full"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

export function Troops() {
  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) =>
          row.canExpand ? (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
            </span>
          ) : null,
      },
      {
        Header: "Unit Id",
        accessor: "unitId",
      },
      {
        Header: "Unit Type",
        accessor: "type",
      },
      {
        Header: "Unit Number",
        accessor: "no",
      },
      {
        Header: "Unit District",
        accessor: "districtName",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Sponsoring Organization",
        accessor: "sponsoringOrg",
      },
    ],
    []
  );

  const [troops, setTroops] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: troops,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    customFetch(`${API_URL}/units`).then((data) => setTroops(data?.units));
  }, []);

  function renderRowSubComponent() {
    return <div>Some expanded info...</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Unit Directory</h1>
          <span>A listing of units in the system</span>
        </div>
        <button className="h-8 px-4 font-medium text-white bg-supernova rounded-lg">
          Add New
        </button>
      </div>
      {troops.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow notHoverable {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    key="globalfilter"
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  // Use a React.Fragment here so the table markup is still valid
                  <React.Fragment {...row.getRowProps()}>
                    <TableRow
                      onClick={() => {
                        navigate(`unit/${row.original.unitId}`);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableData {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableData>
                        );
                      })}
                    </TableRow>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                    {row.isExpanded ? (
                      <TableRow>
                        <TableData colSpan={visibleColumns.length}>
                          {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                          {renderRowSubComponent({ row })}
                        </TableData>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className="pagination py-4">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}
