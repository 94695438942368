import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import customFetch, { API_URL } from "../../utils/fetch";
import { DateTime } from "luxon";
import { PROJECT_ID } from "../../utils/constants";
import { PlanDialogue } from "./dialogue";

function Plan({ planData, idx }) {
  const {
    name,
    effectiveStart,
    effectiveEnd,
    subscriptionStart,
    subscriptionEnd,
    forExistingMembers,
    monthlyFee,
    upfrontMonthly,
    yearlyFee,
  } = planData;

  const variants = {
    popUp: (custom) => {
      return {
        opacity: 1,
        y: 0,
        transition: {
          delay: custom / 7,
        },
      };
    },
  };

  return (
    <motion.div
      key={planData.id}
      custom={idx / 5}
      initial={{ opacity: 0, y: "10%" }}
      animate="popUp"
      variants={variants}
      exit={{ opacity: 0 }}
      positionTransition
      className="xl:w-1/3 lg:w-1/2 md:w-full"
    >
      <div className="mb-4 mr-4 bg-white rounded-lg shadow-md card">
        <div className="flex flex-col items-center justify-center p-4">
          <div className="pb-2 font-bold text-center">{name}</div>
          <div>
            {DateTime.fromISO(effectiveStart).toUTC().toFormat("LLLL dd")} -{" "}
            {DateTime.fromISO(effectiveEnd).toUTC().toFormat("LLLL dd")}
          </div>
          <div className="text-sm">
            Payments through{" "}
            {DateTime.fromISO(subscriptionEnd).toUTC().toFormat("LLLL yyyy")}
          </div>
          <div className="text-sm">
            {forExistingMembers ? "For Existing Members" : "For New Members"}
          </div>
        </div>
        <div className="flex flex-row justify-between p-2 px-4 text-white rounded-b-lg footer bg-darkfly">
          <div className="flex flex-col">
            <div className="text-xs font-bold opacity-75">Upfront Monthly</div>
            <div className="text-sm">
              ${Number.parseFloat(upfrontMonthly).toFixed(2)}
            </div>
          </div>
          <div className="my-2 border-r border-gray-700" />
          <div className="flex flex-col">
            <div className="text-xs font-bold opacity-75">Monthly Fee</div>
            <div>${Number.parseFloat(monthlyFee).toFixed(2)}</div>
          </div>
          <div className="my-2 border-r border-gray-700" />
          <div className="flex flex-col">
            <div className="text-xs font-bold opacity-75">Yearly Fee</div>
            <div className="text-sm">
              ${Number.parseFloat(yearlyFee).toFixed(2)}
            </div>
          </div>
          {subscriptionStart ? (
            <>
              <div className="my-2 border-r border-gray-700" />
              <div className="flex flex-col">
                <div className="text-xs font-bold opacity-75">
                  Subscription Start
                </div>
                <div className="text-sm">
                  {DateTime.fromISO(subscriptionStart)
                    .toUTC()
                    .toFormat("LLLL dd")}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
}

export default function Plans() {
  const [plans, setPlans] = useState([]);
  let [isOpen, setIsOpen] = useState(false);

  function fetchPlans() {
    customFetch(
      `${API_URL}/subscriptionplans/${PROJECT_ID}/all`
    ).then(({ subscriptionPlans }) => setPlans(subscriptionPlans.reverse()));
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  const activePlans = plans.filter(
    (plan) =>
      DateTime.fromISO(plan.effectiveStart) <= DateTime.utc() &&
      DateTime.fromISO(plan.effectiveEnd) >= DateTime.utc()
  );
  const futurePlans = plans.filter(
    (plan) => DateTime.fromISO(plan.effectiveStart) >= DateTime.utc()
  );
  const inactivePlans = plans.filter(
    (plan) => DateTime.fromISO(plan.effectiveEnd) <= DateTime.utc()
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PlanDialogue
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onCreatedPlan={fetchPlans}
      />
      <div className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Active Plans</h1>
          <span>Manage subscription and other pricing plans here</span>
        </div>
        <button
          onClick={() => setIsOpen(true)}
          className="h-8 px-4 font-medium text-white rounded-lg bg-supernova"
        >
          Add new payment plan
        </button>
      </div>
      <AnimatePresence>
        <motion.div className="flex flex-wrap">
          {activePlans.length === 0 ? (
            <div className="w-full pt-4 font-semibold text-center">
              No active plans. Please create plans using the 'Add new payment
              plan' button
            </div>
          ) : (
            activePlans.map((plan, idx) => (
              <Plan key={plan.id} planData={plan} idx={idx} />
            ))
          )}
        </motion.div>
        <motion.div className="flex flex-wrap">
          <div className="py-6">
            <h2 className="pt-8 text-2xl font-medium">Future Plans</h2>
            <span>Old plans that are not active anymore</span>
          </div>
          {futurePlans.length === 0 ? (
            <div className="w-full pt-4 font-semibold text-center">
              No future plans. If there are no active plans, the checkout page
              will not be able to progress for users.
            </div>
          ) : (
            futurePlans.map((plan, idx) => (
              <Plan key={plan.id} planData={plan} idx={idx} />
            ))
          )}
        </motion.div>
        <div>
          <div className="py-6">
            <h2 className="pt-8 text-2xl font-medium">Inactive Plans</h2>
            <span>Old plans that are not active anymore</span>
          </div>
          <div className="flex flex-wrap">
            {inactivePlans.map((plan, idx) => (
              <Plan key={plan.id} planData={plan} idx={idx} />
            ))}
          </div>
        </div>
      </AnimatePresence>
    </motion.div>
  );
}
