/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Input } from "./App";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import customFetch, {
  API_URL,
  webTokenKey,
  BASE_API_URL,
  isDev,
} from "./utils/fetch";
import logo from "./assets/logo.png";
import scoutpayLogo from "./assets/scoutpaylogo.png";
import { PROJECT_ID } from "./utils/constants";
import Checkmark from "./assets/checkmark";
import jwt from "jsonwebtoken";
import Cleave from "cleave.js/react";
import { DateTime } from "luxon";
import { useRef } from "react";
import LogRocket from "logrocket";
import { X } from "heroicons-react";
import { navigate } from "hookrouter";
import { RadioGroup } from "@headlessui/react";

function ErrorText({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="pl-2 mb-3 text-sm text-red-400"
    >
      {children}
    </motion.div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// eslint-disable-next-line no-unused-vars
function SelectScout({ onChange }) {
  const scouts = [
    {
      id: "123123123",
      name: "Scout Scouter",
      description: "Unit 123123123",
    },
    {
      id: "newScout",
      name: "Create New Scout",
      description: "Add a new scout to manage",
    },
  ];
  const [selected, setSelected] = useState("newScout");
  onChange(selected);

  return (
    <RadioGroup
      value={selected}
      onChange={(v) => {
        setSelected(v);
      }}
    >
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="-space-y-px bg-white rounded-md">
        {scouts.map((scout, settingIdx) => (
          <RadioGroup.Option
            key={scout.name}
            value={scout.id}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                settingIdx === scouts.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-darkfly border-indigo-200 z-10"
                  : "border-gray-200",
                "relative border p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-supernova border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-supernova" : "",
                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="flex flex-col ml-3">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-white" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {scout.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? "text-white" : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {scout.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

function LoginForm({ setUserToken, setShowLoginForm }) {
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(false);

  function onSubmit({ loginEmail: email, loginPassword: password }) {
    fetch(`${BASE_API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (resp) => {
      if (resp.status === 400) {
        return setError(true);
      }
      const json = await resp.json();
      localStorage.setItem(webTokenKey, json.jwtToken);
      setUserToken(json.jwtToken);
      setShowLoginForm(false);
    });
  }

  return (
    <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-screen h-screen bg-black bg-opacity-75">
      <div className="w-full max-w-screen-sm p-8 bg-gray-100">
        <div className="flex justify-end w-full pb-4">
          <X
            className="cursor-pointer"
            onClick={() => setShowLoginForm(false)}
          />
        </div>
        {error && (
          <div className="w-full p-2 mb-4 border border-red-600 border-solid rounded">
            Your username or password is incorrect
          </div>
        )}
        <h3 className="pb-4 text-2xl font-medium">Login</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Input
              name="loginEmail"
              placeholder="Email Address"
              hasError={errors.loginEmail}
              passedRef={register({
                required: {
                  value: true,
                  message: <ErrorText>Email address is required</ErrorText>,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: <ErrorText>Invalid email address</ErrorText>,
                },
              })}
            />
            {errors.loginEmail && errors.loginEmail.message}
          </div>
          <div className="">
            <Input
              name="loginPassword"
              type="password"
              placeholder="Password"
              hasError={errors.loginPassword}
              passedRef={register({
                required: true,
              })}
            />
            {errors.loginPassword && (
              <ErrorText>Password is required</ErrorText>
            )}
          </div>
          <button
            type="submit"
            className="p-2 mr-2 text-center rounded-lg text-supernova bg-darkfly"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export function Header({
  isLoggedIn,
  userToken,
  setUserToken,
  setShowLoginForm,
}) {
  return (
    <div className="flex justify-center w-full text-gray-200 bg-darkfly">
      <div className="flex justify-end w-full max-w-screen-md">
        <button
          className="p-4 hover:cursor-pointer hover:text-supernova"
          onClick={() => (window.location.href = "/leader/portal")}
        >
          Leader Portal
        </button>
        {isLoggedIn ? (
          <>
            <div className="p-4">
              Logged in as {jwt.decode(userToken).email}
            </div>
            <button
              className="p-4 hover:cursor-pointer hover:text-supernova"
              onClick={() => {
                localStorage.removeItem(webTokenKey);
                setUserToken(undefined);
              }}
            >
              Logout
            </button>
          </>
        ) : (
          <button
            onClick={() => setShowLoginForm(true)}
            className="p-4 hover:cursor-pointer hover:text-supernova"
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}
function CheckoutForm({
  register,
  errors,
  isLoggedIn,
  units,
  userToken,
  setUserToken,
  scoutId,
  returningAfterBreak,
  setReturningAfterBreak,
  handleScoutSelectChange,
  memberType,
  troopNumber,
  setShowLoginForm,
  setUnitId,
  unitId,
}) {
  function renderYourInfo() {
    if (isLoggedIn) {
      return (
        <>
          <h1 className="pb-6 text-2xl font-semibold">Logged In As</h1>
          <div>
            <div className="flex">
              <div>{jwt.decode(userToken).email}</div>
              <div className="px-4">|</div>
              <div
                onClick={() => {
                  localStorage.removeItem("login-token");
                  setUserToken(null);
                }}
                className="text-blue-600 cursor-pointer hover:underline"
              >
                Logout
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <h1 className="pb-6 text-2xl font-semibold">
          Parent/Guardian Information
        </h1>
        <div className="flex">
          <div className="w-1/2 pr-4">
            <Input
              name="payerFirstName"
              hasError={errors.payerFirstName}
              placeholder="First Name"
              passedRef={register({ required: true })}
            />
            {errors.payerFirstName && (
              <ErrorText>This field is required</ErrorText>
            )}
          </div>
          <div className="w-1/2">
            <Input
              name="payerLastName"
              hasError={errors.payerLastName}
              placeholder="Last Name"
              passedRef={register({ required: true })}
            />
            {errors.payerLastName && (
              <ErrorText>This field is required</ErrorText>
            )}
          </div>
        </div>
        <div className="">
          <Input
            name="email"
            placeholder="Email Address"
            hasError={errors.email}
            passedRef={register({
              required: {
                value: true,
                message: <ErrorText>Email address is required</ErrorText>,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: <ErrorText>Invalid email address</ErrorText>,
              },
            })}
          />
          {errors.email && errors.email.message}
        </div>
        <div className="">
          <Input
            name="password"
            type="password"
            placeholder="Password"
            hasError={errors.password}
            passedRef={register({
              required: true,
            })}
          />
          {errors.password && <ErrorText>Password is required</ErrorText>}
        </div>
        <div></div>
        <div className="">
          <Cleave
            name="phone"
            placeholder="Phone Number"
            className="w-full h-12 p-2 px-3 placeholder-gray-400 rounded-lg"
            htmlRef={register({
              required: true,
            })}
            options={{
              blocks: [3, 3, 4],
              delimiter: "-",
              numericOnly: true,
            }}
          />
          {errors.phone && <ErrorText>Phone Number is required</ErrorText>}
        </div>
      </>
    );
  }

  const unit = units.filter((unit) => unit.unitId === unitId?.toString?.());

  return (
    <motion.div
      key="checkout-form"
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 0, x: -150 }}
      className={`w-full`}
    >
      <h1 className="pb-6 text-2xl font-semibold">Scout Information</h1>
      {scoutId === "newScout" && (
        <div className="flex">
          <div className="w-1/2 pr-4">
            <Input
              name="firstName"
              hasError={errors.firstName}
              placeholder="Scout First Name"
              passedRef={register({ required: true })}
            />
            {errors.firstName && <ErrorText>This field is required</ErrorText>}
          </div>
          <div className="w-1/2">
            <Input
              name="lastName"
              hasError={errors.lastName}
              placeholder="Scout Last Name"
              passedRef={register({ required: true })}
            />
            {errors.lastName && <ErrorText>This field is required</ErrorText>}
          </div>
        </div>
      )}
      <div className="flex">
        <div className="w-full">
          <Input
            name="troopNumber"
            type="number"
            disabled
            className="bg-gray-300"
            hasError={true}
            onChange={(e) => {
              setUnitId(e.target.value);
            }}
            placeholder="Unit ID"
            passedRef={register}
          />
        </div>
      </div>
      {memberType === "new" && (
        <div className="relative flex items-start py-4">
          <div className="flex items-center h-5">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              value={returningAfterBreak}
              onChange={(e) => setReturningAfterBreak?.(e.target.checked)}
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded focus:ring-supernova text-supernova"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              If your membership lapsed, click this box to sign up as a
              New/Returning Scout.
            </label>
          </div>
        </div>
      )}
      <div className="flex flex-col pb-4">
        {unit.length > 0 && (
          <div className="flex justify-between w-full pt-4">
            <div className="flex flex-col">
              <div className="opacity-75">Unit Type</div>
              <div>{unit[0].type}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Unit Number</div>
              <div>{unit[0].no}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Sponsoring Org</div>
              <div>{unit[0].sponsoringOrg}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">City</div>
              <div>{unit[0].city}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Distict Name</div>
              <div>{unit[0].districtName}</div>
            </div>
          </div>
        )}
      </div>
      {renderYourInfo()}

      <div className="flex items-center mt-8">
        {isLoggedIn ? (
          <button
            type="submit"
            className="px-4 py-2 mr-2 text-center rounded-lg text-supernova bg-darkfly"
          >
            Checkout
          </button>
        ) : (
          <>
            <button
              type="submit"
              className="px-4 py-2 mr-2 text-center rounded-lg text-supernova bg-darkfly"
            >
              Checkout and Register
            </button>
            <div>
              or{" "}
              <span
                onClick={() => setShowLoginForm(true)}
                className="text-blue-600 cursor-pointer hover:underline"
              >
                Sign In
              </span>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
}

function Addon({ addon, onAdd, onRemove, onUpdate, isAdded }) {
  const [price, setSelectedPrice] = useState(addon.priceOptions[0]);

  function handlePriceUpdate(newPrice) {
    if (isAdded) {
      onUpdate(newPrice);
    } else {
      onAdd(newPrice);
    }
    setSelectedPrice(newPrice);
  }

  function handleClick(e) {
    e.preventDefault();
    if (isAdded) {
      onRemove();
    } else {
      onAdd(price);
    }
  }

  return (
    <div className="flex items-center justify-between w-full p-2 px-4 mb-4 rounded-lg bg-darkfly text-pampas">
      <div className="flex">
        <button
          onClick={handleClick}
          className="self-center px-4 py-1 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
        >
          {isAdded ? "Remove" : "Add"}
        </button>
        <div className="flex flex-col">
          <div className="font-semibold">{addon?.name}</div>
          <div>
            {addon.occurrence === "YEARLY" ? "Renews yearly" : "Renews monthly"}
          </div>
        </div>
      </div>
      <div className="flex">
        {addon.priceOptions.map((priceOpt) => {
          const isSelected = priceOpt === price;
          return (
            <div
              onClick={() => handlePriceUpdate(priceOpt)}
              className={`p-2 ml-2 rounded-lg cursor-pointer ${
                isSelected ? "bg-pampas text-darkfly" : "bg-darkfly"
              } hover:bg-pampas hover:text-firefly`}
            >
              ${Number.parseFloat(priceOpt).toFixed(2)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

let processedAddons = false;

export default function Checkout({ setOrderId, troopNumber }) {
  const PLANS = { MONTHLY: 1, YEARLY: 2 };
  const [showCheckoutIframe, setShowIframe] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [order, _setOrder] = useState({});
  const [cards, setCards] = useState();
  const [plan, setPlan] = useState();
  const [memberType, setMemberType] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [selectedPlan, setSelectedPlan] = useState(PLANS.MONTHLY);
  const [returningAfterBreak, setReturningAfterBreak] = useState(false);
  const [donePaying, setDonePaying] = useState();
  const [payError, setPayError] = useState(false);
  const isMonthly = selectedPlan === PLANS.MONTHLY;
  const [userToken, setUserToken] = useState(localStorage.getItem(webTokenKey));
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [units, setUnits] = useState();
  const [magazineAddon, setMagazineAddon] = useState(false);
  const isLoggedIn = jwt.decode(userToken)?.exp > new Date().getTime() / 1000;
  // Relevant addons to show the user
  const [addons, setAddons] = useState([]);
  const [selectedAddons, _setSelectedAddons] = useState([]);
  const [unitId, _setUnitId] = useState(troopNumber);
  const [doneAddingAddons, setDoneAddingAddons] = useState(false);
  const [scout, _setScout] = useState();
  const orderRef = useRef(order);
  const selectedAddonsRef = useRef(selectedAddons);
  const scoutRef = useRef(scout);
  const unitIdRef = useRef(unitId);
  const [scoutId, handleScoutSelectChange] = useState("newScout");

  const {
    register,
    handleSubmit,
    errors,
    setError,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      troopNumber,
    },
  });

  const setOrder = (order) => {
    orderRef.current = order;
    _setOrder(order);
  };

  const setSelectedAddons = (addons) => {
    selectedAddonsRef.current = addons;
    _setSelectedAddons(addons);
  };

  const setScout = (scout) => {
    scoutRef.current = scout;
    _setScout(scout);
  };

  const setUnitId = (unit) => {
    unitIdRef.current = unit;
    _setUnitId(unit);
  };

  const onSubmit = (data) => {
    const {
      email,
      password,
      firstName,
      lastName,
      troopNumber,
      phone,
      payerFirstName,
      payerLastName,
    } = data;
    customFetch(`${API_URL}/orders/boyscouts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: PROJECT_ID,
        plan,
        isLeader: false,
        isMonthly,
        memberType,
        unitId: troopNumber,
        magazineAddon,
        returningAfterBreak,
        payer: {
          firstName: payerFirstName,
          lastName: payerLastName,
          email,
          password,
          phone,
        },
        scout: {
          firstName,
          lastName,
        },
      }),
    }).then((resp) => {
      if (resp.status === 400) {
        setError("email", "manual", <ErrorText>{resp.message}</ErrorText>);
        return;
      }
      setOrder(resp.order);
      setCards(resp.tokenizedCards);
      setScout(resp.scout);
      if (resp.jwtToken) {
        localStorage.setItem(webTokenKey, resp.jwtToken);
        setUserToken(resp.jwtToken);
      }
      setShowCheckout(true);
      if (email) {
        setShowIframe(true);
      }
    });
  };

  const handleTokenizedSubmit = (e) => {
    e.preventDefault();
    customFetch(`${API_URL}/orders/${order?.id}/closeTokenizeAndPayUpFront`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokenizedCardId: selectedCard?.id,
      }),
    }).then(() => {
      setDonePaying(true);
    });
  };

  const handleAddAddon = (addon) => (price) => {
    setSelectedAddons([...selectedAddons, { ...addon, price }]);
  };

  const handleRemove = (addon) => () => {
    setSelectedAddons([...selectedAddons.filter((add) => add.id !== addon.id)]);
  };

  const handleAddonUpdate = (addon) => (price) => {
    setSelectedAddons([
      ...selectedAddons.filter((add) => add.id !== addon.id),
      { ...addon, price },
    ]);
  };

  function updateOrderStatusToClosed(orderId, worldpayReturn, orderDetails) {
    if (orderId) {
      fetch(`${API_URL}/orders/${orderId}/closeTokenizeAndPayUpFront`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          worldpayReturn,
          orderDetails,
          card: {
            lastFour: worldpayReturn.LastFour,
            cardType: worldpayReturn.CardLogo,
          },
        }),
      });
    }
  }

  useEffect(() => {
    const dataListener = (event) => {
      if (
        typeof event.data === "string" &&
        (event.data[0] === "{" || event.data[0] === "[")
      ) {
        const eventData = JSON.parse(event?.data);
        // Response from child iframe
        if (eventData?.message === "worldpay-transaction" && order?.id !== "") {
          if (
            eventData?.serializedParams?.ExpressResponseMessage === "Approved"
          ) {
            updateOrderStatusToClosed(
              orderRef.current?.id,
              eventData?.serializedParams
            );
          } else {
            setPayError(true);
          }
          setDonePaying(true);
        }
      }
    };

    window.addEventListener("message", dataListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (donePaying && !processedAddons) {
      processedAddons = true;
      const addonPromises = [];
      // Create the addons
      selectedAddonsRef.current.forEach((addonToProcess) => {
        addonPromises.push(
          customFetch(`${API_URL}/subscriptions/addon`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              addonId: addonToProcess.id,
              price: addonToProcess.price,
              scoutId: scoutRef.current?.id,
              unitId: unitIdRef.current,
            }),
          })
        );
      });

      Promise.all(addonPromises).then(() => setDoneAddingAddons(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donePaying]);

  useEffect(() => {
    !isDev && LogRocket.init("mshgpb/scoutpay");
    customFetch(`${API_URL}/units/project/${PROJECT_ID}`).then(({ units }) => {
      setUnits(units);
      const unitId = units.filter(
        (unit) => unit.unitId === Number.parseInt(troopNumber)
      )[0];
      setValue("unitId", unitId?.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Boolean(userToken) && !isDev) {
      LogRocket.identify(jwt.decode(userToken)?.id, {
        email: jwt.decode(userToken)?.email,
      });
    }
  }, [userToken]);

  useEffect(() => {
    if (showCheckout) {
      customFetch(`${API_URL}/addons/relevant`).then(({ addons }) => {
        setAddons(addons);
      });
    }
  }, [showCheckout]);

  useEffect(() => {
    if (memberType) {
      customFetch(
        `${API_URL}/subscriptionplans/${PROJECT_ID}`,
        null,
        false
      ).then(({ subscriptionPlans }) => {
        let plan;
        if (memberType === "new") {
          plan = subscriptionPlans.filter((pl) => !pl.forExistingMembers);
        } else {
          plan = subscriptionPlans.filter((pl) => pl.forExistingMembers);
        }
        setPlan(plan?.[0]);
      });
    }
  }, [memberType]);

  useEffect(() => {
    if (order?.transactionSetupId && cards && cards?.length === 0) {
      setShowIframe(true);
    }
    if (order?.id) {
      setOrderId(order?.id);
    }
  }, [order, cards, setOrderId]);

  const cartTotal = selectedAddons.reduce(
    (total, addon) => total + addon.price,
    order?.upfrontAmount
  );

  return units && plan && memberType ? (
    <div className="w-full">
      <Header
        isLoggedIn={isLoggedIn}
        userToken={userToken}
        setUserToken={setUserToken}
        setShowLoginForm={setShowLoginForm}
      />
      <div className="flex flex-col items-center w-full">
        <div className="flex py-10">
          <img src={scoutpayLogo} className="w-64" alt="logo" />
        </div>
        {showLoginForm && (
          <LoginForm
            setUserToken={setUserToken}
            setShowLoginForm={setShowLoginForm}
          />
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full max-w-screen-md px-4 py-4 mx-auto"
        >
          <motion.div
            animate
            className="flex flex-col px-10 py-6 mb-4 text-xl font-medium rounded-lg bg-darkfly text-supernova"
          >
            <AnimatePresence exitBeforeEnter>
              {showCheckout ? (
                <motion.div
                  key="checkout-selected"
                  initial={{ opacity: 0, width: "50%" }}
                  animate={{ opacity: 1, width: "100%" }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: "spring",
                    mass: 0.2,
                    tension: 182,
                  }}
                >
                  <motion.div
                    className={`w-full mr-8 bg-pampas text-darkfly ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                  >
                    <motion.div>
                      Pay Per {isMonthly ? "Month" : "Year"}
                    </motion.div>
                    <motion.div>
                      <motion.span className="text-4xl font-bold">
                        $
                        {Number.parseFloat(
                          isMonthly ? plan?.monthlyFee : plan?.yearlyFee
                        ).toFixed(2)}
                      </motion.span>
                      /{isMonthly ? "month" : "year"}
                      {isMonthly && (
                        <>
                          <motion.div>
                            after $
                            {Number.parseFloat(
                              returningAfterBreak
                                ? plan?.upfrontMonthly - 25
                                : plan?.upfrontMonthly
                            ).toFixed(2)}{" "}
                            up front
                          </motion.div>
                          <motion.div>
                            charges through{" "}
                            {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                              "MMMM y"
                            )}
                          </motion.div>
                        </>
                      )}
                    </motion.div>
                  </motion.div>
                </motion.div>
              ) : (
                <motion.div
                  key="checkout-unselected"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <motion.div className="pl-2 text-3xl">Select Plan</motion.div>
                  <motion.div className="w-64 my-2 mb-8 border-t opacity-50 border-supernova" />
                  <motion.div className="flex flex-col items-start justify-between w-full sm:flex-row">
                    <motion.div
                      onClick={() => setSelectedPlan(PLANS.MONTHLY)}
                      className={`w-full sm:w-1/2 mb-4 mr-8 hover:bg-pampas hover:text-darkfly ${
                        selectedPlan === PLANS.MONTHLY &&
                        "bg-pampas text-darkfly"
                      } ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                    >
                      <motion.div>Pay Per Month</motion.div>
                      <motion.div>
                        <motion.span className="text-4xl font-bold">
                          ${Number.parseFloat(plan?.monthlyFee).toFixed(2)}
                        </motion.span>
                        /month
                        <motion.div>
                          after $
                          {Number.parseFloat(
                            returningAfterBreak
                              ? plan?.upfrontMonthly - 25
                              : plan?.upfrontMonthly
                          ).toFixed(2)}{" "}
                          up front
                        </motion.div>
                        <motion.div className="text-sm">
                          charges through{" "}
                          {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                            "MMMM y"
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div
                      onClick={() => setSelectedPlan(PLANS.YEARLY)}
                      className={`w-full h-full sm:w-1/2 mb-4 mr-8 hover:bg-pampas hover:text-darkfly ${
                        selectedPlan === PLANS.YEARLY &&
                        "bg-pampas text-darkfly"
                      } ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                    >
                      <motion.div>Pay Per Year</motion.div>
                      <motion.div>
                        <motion.span className="text-4xl font-bold">
                          $
                          {Number.parseFloat(
                            returningAfterBreak
                              ? plan?.yearlyFee - 25
                              : plan?.yearlyFee
                          ).toFixed(2)}
                        </motion.span>
                        /year
                        <motion.div className="text-sm">
                          charges through{" "}
                          {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                            "MMMM y"
                          )}
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="w-full my-2 mt-10 border-t opacity-50 border-supernova" />
            <div className="flex w-full text-base">
              <div className="w-1/2">
                <div>Michigan Crossroads Council</div>
              </div>
              <div className="w-1/2 text-right">
                For {memberType === "new" ? "new" : "existing"} scouts
              </div>
            </div>
          </motion.div>
          <div className="flex">
            <AnimatePresence exitBeforeEnter>
              {showCheckout ? (
                <motion.div
                  key="iframe-checkout"
                  initial={{ opacity: 0, x: 150 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.15 }}
                  exit={{ opacity: 0, x: -150 }}
                  className="w-full"
                >
                  {!donePaying && (
                    <>
                      <h1 className="pb-6 text-2xl font-semibold">Addons</h1>
                      <div className="flex flex-col">
                        {addons.map((addon) => (
                          <Addon
                            addon={addon}
                            isAdded={selectedAddons.some(
                              (add) => add.id === addon.id
                            )}
                            onAdd={handleAddAddon(addon)}
                            onRemove={handleRemove(addon)}
                            onUpdate={handleAddonUpdate(addon)}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  <h1 className="pt-6 pb-6 text-2xl font-semibold">Payment</h1>
                  {donePaying && doneAddingAddons && !payError ? (
                    <div className="flex flex-col">
                      <div className="pb-6 text-lg font-semibold">
                        Successfully paid!
                      </div>
                      <div className="flex flex-col">
                        <div className="pb-2 text-lg font-semibold">
                          Order Details:
                        </div>
                        {selectedAddons.map((selAddon) => (
                          <div className="flex py-1">
                            <span className="w-48 break-word">
                              {selAddon.name}:
                            </span>{" "}
                            ${Number.parseFloat(selAddon.price).toFixed(2)}
                            {selAddon?.occurrence === "YEARLY"
                              ? "/year"
                              : "/month"}
                          </div>
                        ))}
                        <div className="flex py-1">
                          <span className="w-48">Upfront Membership Fee:</span>{" "}
                          ${Number.parseFloat(order?.upfrontAmount).toFixed(2)}
                        </div>
                        {order?.subscription?.occurence === "MONTHLY" && (
                          <div className="flex py-1">
                            <span className="w-48">Membership Fee:</span> $
                            {Number.parseFloat(
                              order?.subscriptionAmount
                            ).toFixed(2)}
                            /month through{" "}
                            {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                              "MMMM y"
                            )}
                          </div>
                        )}
                        <div className="flex pt-2 font-semibold">
                          <span className="w-48">Total Paid Today:</span> $
                          {Number.parseFloat(cartTotal).toFixed(2)}
                        </div>
                        <button
                          className="px-4 py-2 mt-6 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.reload();
                          }}
                        >
                          Pay for another Scout
                        </button>
                      </div>
                    </div>
                  ) : donePaying && payError ? (
                    <div className="flex flex-col">
                      <div className="pb-6 text-lg font-semibold">
                        There was an issue with your card.
                      </div>
                      <div className="flex flex-col">
                        <button
                          className="px-4 py-2 mt-6 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.reload();
                          }}
                        >
                          Try Again
                        </button>
                      </div>
                    </div>
                  ) : (
                    !showCheckoutIframe &&
                    !donePaying && (
                      <div className="flex flex-col">
                        <div className="pb-6 text-lg font-semibold">
                          Processing Your Transaction
                        </div>
                      </div>
                    )
                  )}
                  {!showCheckoutIframe && !donePaying && cards?.length > 0 && (
                    <div>
                      {cards.map((card) => {
                        const selected = selectedCard?.id === card?.id;

                        return (
                          <div
                            onClick={() => setSelectedCard(card)}
                            className={`w-full cursor-pointer p-2 px-4 mb-4 bg-gray-200 rounded border ${
                              selected ? "border-green-400" : "border-gray-400"
                            } flex h-full items-center`}
                          >
                            <div className="flex pr-4 align-middle">
                              <div
                                className={`rounded-full w-6 h-6 border ${
                                  selected
                                    ? "border-green-600 bg-green-300"
                                    : "border-gray-400 bg-gray-300"
                                } flex items-center justify-center`}
                              >
                                <Checkmark className="w-3 h-3 text-white" />
                              </div>
                            </div>
                            <div className="pt-2">
                              <div className="text-sm uppercase">
                                {card?.cardType}
                              </div>
                              <div style={{ verticalAlign: "sub" }}>
                                <span
                                  style={{
                                    lineHeight: "30px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  **** **** ****
                                </span>{" "}
                                {card?.lastFour}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex justify-between pt-6">
                        <div>
                          {selectedCard && (
                            <button
                              onClick={handleTokenizedSubmit}
                              className="px-4 py-2 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
                            >
                              Pay with {selectedCard?.lastFour}
                            </button>
                          )}
                          <button
                            className="px-4 py-2 mr-4 font-medium text-white rounded-lg bg-supernova hover:bg-yellowsea"
                            onClick={() => setShowIframe(true)}
                          >
                            Pay with new card
                          </button>
                        </div>
                        <div animate className="text-2xl font-semibold">
                          Total: ${Number.parseFloat(cartTotal).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )}
                  {showCheckoutIframe && !donePaying && (
                    <>
                      <div className="text-2xl font-semibold">
                        Total: ${Number.parseFloat(cartTotal).toFixed(2)}
                      </div>
                      <iframe
                        title="Checkout Form"
                        className={`w-full overflow-hidden overflow-y-hidden overflow-x-hidden`}
                        style={{ height: "30rem" }}
                        src={`https://${
                          isDev ? "certtransaction" : "transaction"
                        }.hostedpayments.com/?TransactionSetupID=${
                          order?.transactionSetupId
                        }`}
                      />
                    </>
                  )}
                </motion.div>
              ) : (
                selectedPlan && (
                  <CheckoutForm
                    userToken={userToken}
                    units={units}
                    getValues={getValues}
                    memberType={memberType}
                    setUserToken={setUserToken}
                    isLoggedIn={isLoggedIn}
                    scoutId={scoutId}
                    handleScoutSelectChange={handleScoutSelectChange}
                    returningAfterBreak={returningAfterBreak}
                    setReturningAfterBreak={setReturningAfterBreak}
                    setShowLoginForm={setShowLoginForm}
                    magazineAddon={magazineAddon}
                    setMagazineAddon={setMagazineAddon}
                    troopNumber={troopNumber}
                    setUnitId={setUnitId}
                    unitId={unitId}
                    register={register}
                    errors={errors}
                  />
                )
              )}
            </AnimatePresence>
          </div>
        </form>
        <div>
          <img src={logo} className="w-32" alt="logo" />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full">
      <Header
        isLoggedIn={isLoggedIn}
        userToken={userToken}
        setUserToken={setUserToken}
        setShowLoginForm={setShowLoginForm}
      />
      <div className="flex flex-col items-center w-full">
        <div className="flex py-10">
          <img src={scoutpayLogo} className="w-64" alt="logo" />
        </div>
        {showLoginForm && (
          <LoginForm
            setUserToken={setUserToken}
            setShowLoginForm={setShowLoginForm}
          />
        )}
        <motion.div
          animate
          className="flex flex-col px-10 py-6 mt-4 mb-4 text-xl font-medium rounded-lg bg-darkfly text-supernova"
        >
          <h1 className="pb-8 text-2xl">
            Are you paying for a new member or an existing member?
          </h1>
          <motion.div className="flex flex-col items-center justify-between w-full pb-8 sm:flex-row">
            <motion.div
              onClick={() => setMemberType("new")}
              className={`w-full sm:w-1/2 mr-8 mb-4 text-center hover:bg-pampas hover:text-darkfly ease-in transition duration-75 cursor-pointer p-8 rounded-lg shadow border-r-2 border-t border-l border-b-2 border-opacity-75 border-black`}
            >
              New Member
            </motion.div>
            <motion.div
              onClick={() => setMemberType("existing")}
              className={`w-full sm:w-1/2 mr-8 mb-4 text-center hover:bg-pampas hover:text-darkfly ease-in transition duration-75 cursor-pointer p-8 rounded-lg shadow border-r-2 border-t border-l border-b-2 border-opacity-75 border-black`}
            >
              Existing Member
            </motion.div>
          </motion.div>
        </motion.div>
        <div>
          <button
            onClick={() => navigate(`/checkout/leader/${troopNumber}`)}
            className="pb-8 text-blue-500 underline hover:cursor-pointer"
          >
            Pay for a leader instead?
          </button>
        </div>
        <div>
          <img src={logo} className="w-32" alt="logo" />
        </div>
      </div>
    </div>
  );
}
