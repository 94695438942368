import React, { Fragment, useEffect, useState } from "react";
import customFetch, { API_URL } from "../utils/fetch";
import { PopConfirmButton, PopConfirmLink } from "./components";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import { AnimateSharedLayout, motion } from "framer-motion";
import { DateTime } from "luxon";
import {
  CheckCircleIcon,
  ReceiptRefundIcon,
  FingerPrintIcon,
  CurrencyDollarIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

function SubCol({ title, body, width }) {
  return (
    <div
      className={`pr-4 ${width} overflow-ellipsis overflow-hidden mb-4 lg:mb-0`}
    >
      <div className={`text-gray-600 text-sm`}>{title}</div>
      <div className={``}>{body}</div>
    </div>
  );
}

function ErrorMessage({ title, response }) {
  const [viewResp, setViewResp] = useState(false);

  return (
    <div className="rounded-t-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 w-full">
          <h3 className="text-sm font-medium text-red-800 w-80">{title}</h3>
          {response && (
            <div className="mt-2 text-sm text-red-700 overflow-x-scroll w-full">
              <button
                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer pb-2"
                onClick={() => setViewResp(!viewResp)}
              >
                View Worldpay Response
              </button>
              {viewResp && (
                <pre className="text-xs pr-2">
                  {JSON.stringify(response, null, 2)}
                </pre>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SuccessMessage({ title }) {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">{title}</h3>
        </div>
      </div>
    </div>
  );
}

function PaymentsDrawer({
  open,
  setOpen,
  payments,
  scout,
  refreshSubscriptions,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState({});

  async function refundPayment(payment) {
    if (!payment) {
      return;
    }

    setLoading(true);
    let resp;

    try {
      resp = await customFetch(`${API_URL}/admin/refund/${payment?.id}`, {
        method: "POST",
      });
      if (resp.status === 400) {
        setError({
          ...error,
          [payment?.id]: {
            message: resp.message,
          },
        });
        setLoading(false);
        await refreshSubscriptions();
        return;
      }
    } catch (e) {
      setError({
        ...error,
        [payment?.id]: {
          message: "There was an error, please try again later",
        },
      });
      setLoading(false);
      return;
    }

    if (!resp.success) {
      setError({
        ...error,
        [payment?.id]: {
          message:
            "There was an issue processing the response. Please save the worldpay response",
          worldpayResponse: resp.worldpayResponse,
        },
      });
      await refreshSubscriptions();
      setLoading(false);
      return;
    }

    await refreshSubscriptions();
    setError({
      ...error,
      [payment?.id]: false,
    });
    setSuccess({
      ...success,
      [payment?.id]: {
        message: `Refunded ${payment?.id} for $${payment?.amount?.toFixed?.(
          2
        )}`,
      },
    });
    setLoading(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-1000 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-1000 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Payments for {scout}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}

                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div
                        className="h-full border-gray-200"
                        aria-hidden="true"
                      >
                        <div className="bg-white shadow sm:rounded-md">
                          <ul className="divide-y divide-gray-200">
                            {payments?.map((payment) => {
                              const disabled =
                                payment?.status === "OPEN" ||
                                payment?.status === "CLOSED" ||
                                payment?.status === "REFUNDED" ||
                                payment?.status === "FAILED";

                              const pillColors =
                                payment?.status === "CLOSED"
                                  ? "bg-gray-100 text-gray-800"
                                  : payment?.status === "FAILED"
                                  ? "bg-red-100 text-red-800"
                                  : payment?.status === "REFUNDED"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : "bg-green-100 text-green-800";

                              return (
                                <>
                                  <li key={payment?.id}>
                                    {error[payment?.id] && (
                                      <ErrorMessage
                                        title={error[payment?.id]?.message}
                                        response={
                                          error[payment?.id]?.worldpayResponse
                                        }
                                      />
                                    )}
                                    {success[payment?.id] && (
                                      <SuccessMessage
                                        title={success[payment?.id].message}
                                      />
                                    )}
                                    <div className="px-6 py-4">
                                      <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-indigo-600 truncate">
                                          {DateTime.fromISO(
                                            payment?.paymentDate
                                          ).toFormat("DDD")}
                                        </p>
                                        <div className="ml-2 flex-shrink-0 flex">
                                          <p
                                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${pillColors}`}
                                          >
                                            {payment?.status}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="mt-2">
                                        <div className="">
                                          <p className="mt-2 flex items-center text-sm text-gray-500">
                                            <FingerPrintIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {payment?.id}
                                          </p>
                                          <p className="mt-1 flex items-center text-sm text-gray-500">
                                            <CurrencyDollarIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            {payment?.amount?.toFixed?.(2)}
                                          </p>
                                          <p className="mt-1 flex items-center text-sm text-gray-500">
                                            <ReceiptRefundIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                            <PopConfirmLink
                                              buttonText="Refund Payment"
                                              disabled={disabled}
                                              onConfirm={() => {
                                                if (!loading) {
                                                  refundPayment(payment);
                                                }
                                              }}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Subscription({ subscription, first, refreshSubscriptions }) {
  const [showDrawer, setShowDrawer] = useState(false);

  const scoutName = `${subscription?.Scout?.firstName} ${subscription?.Scout?.lastName}`;

  return (
    <motion.div
      key={subscription?.id}
      className={` ${
        !first && "border-t"
      } border-gray-300 p-4 flex flex-col items-start lg:flex-row`}
    >
      <PaymentsDrawer
        open={showDrawer}
        setOpen={setShowDrawer}
        payments={subscription?.payments}
        scout={scoutName}
        refreshSubscriptions={refreshSubscriptions}
      />
      <div className={"flex flex-col lg:flex-row"}>
        <SubCol title={"Scout"} width={"w-40"} body={scoutName} />
        <SubCol
          title={"Plan"}
          width={"w-48"}
          body={
            subscription?.subscriptionPlan
              ? subscription?.subscriptionPlan?.name
              : subscription?.addon?.name
          }
        />
        <SubCol title={"Occurrence"} body={subscription?.occurence} />
        <SubCol
          title={"Amount"}
          body={`$${Number(subscription?.amount).toFixed(2)}`}
        />
        <SubCol title={"Status"} body={subscription?.status} />
      </div>
      <div className="lg:ml-auto lg:py-0 pt-4 h-full items-center flex">
        <button
          className={
            "inline-flex items-center px-4 py-2 mr-2 border border-gray-400 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
          onClick={() => setShowDrawer(true)}
        >
          View Payments
        </button>
        <PopConfirmButton
          buttonText={"Cancel"}
          disabled={subscription?.status !== "OPEN"}
          onConfirm={async () => {
            await customFetch(
              `${API_URL}/subscriptions/${subscription?.id}/cancel`,
              {
                method: "POST",
              }
            );
            await refreshSubscriptions();
            toast.success(`Cancelled subscription for ${scoutName}`, {
              autoClose: 5000,
            });
          }}
        />
      </div>
    </motion.div>
  );
}

export default function Payer({ payerId }) {
  const [subscriptions, setSubscriptions] = useState([]);

  function fetchSubscriptions() {
    return customFetch(`${API_URL}/admin/payers/${payerId}/subscriptions`).then(
      (data) => {
        setSubscriptions(data?.subscriptions);
      }
    );
  }

  useEffect(() => {
    // Fetch subscriptions with payments for a payer ID
    fetchSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-col bg-white rounded-lg pb-24">
      <div className={`p-4 py-6 border-b border-gray-400 flex flex-col`}>
        <div>
          <h2 className={`text-xl`}>Subscriptions</h2>
          <div>{subscriptions?.[0]?.payer?.email}</div>
          <div className="text-xs">{subscriptions?.[0]?.payer?.id}</div>
        </div>
        <div></div>
      </div>
      <AnimateSharedLayout>
        {subscriptions.map((sub, idx) => (
          <Subscription
            subscription={sub}
            first={idx === 0}
            refreshSubscriptions={fetchSubscriptions}
          />
        ))}
      </AnimateSharedLayout>
    </div>
  );
}
