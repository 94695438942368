import React, { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Tabs({ tabs }) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          defaultValue={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={() => setCurrentTab(tab)}
              className={classNames(
                tab.name === currentTab.name
                  ? "bg-supernova text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
              )}
              aria-current={tab.name === currentTab.name ? "page" : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      {tabs.map((tab) => (
        <div className={`${tab.name === currentTab.name ? "" : "hidden"}`}>
          {tab.content}
        </div>
      ))}
    </div>
  );
}
