import React, { useState, useEffect } from "react";

import { TableRow, Table, TableData, TableHeader } from "./components";
import {
  useTable,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ChevronRight, ChevronDown, UserAdd } from "heroicons-react";
import { motion } from "framer-motion";
import customFetch, { API_URL } from "../utils/fetch";
import { toast } from "react-toastify";
import { usePopper } from "react-popper";
import "@popperjs/core";
import { useForm } from "react-hook-form";
import { navigate } from "hookrouter/dist/router";

async function updateToLeader(user, unitNos) {
  const resp = await customFetch(`${API_URL}/admin/userToLeader/${user?.id}`, {
    method: "POST",
    body: JSON.stringify({
      unitNos,
    }),
  });
  if (resp.connected) {
    const connectedList = resp.connected.map((conn) => conn.unitId);
    toast.success(
      `${user.email} connected to Units ${connectedList.join(", ")}`,
      {
        autoClose: 5000,
      }
    );
  } else {
    toast.error(`${user.email} failed to connect to any units`, {
      autoClose: 5000,
    });
  }
}

function ErrorText({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex-wrap w-48 pl-2 mb-3 text-sm text-red-400"
    >
      {children}
    </motion.div>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  return (
    <div className="flex items-center p-4">
      <div>Search: </div>
      <input
        className="w-full p-4 ml-2"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

function UpgradeToLeaderButton({ user }) {
  const { handleSubmit, register, errors } = useForm();
  const [showPopup, setShowPopup] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  function onSubmit(values) {
    const { unitNos } = values;
    const parsedNos = unitNos.split(",");
    updateToLeader(user, parsedNos);
    setShowPopup(false);
  }

  return (
    <div>
      <UserAdd
        type="button"
        className="cursor-pointer"
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopup(!showPopup);
        }}
      />

      {showPopup && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={setPopperElement}
          style={styles.popper}
          className="flex flex-col mb-4 bg-white rounded-lg shadow-xs shadow shadow-lg"
          {...attributes.popper}
        >
          <div className="flex flex-col p-4">
            <label className="pb-2 text-sm" htmlFor="unitIds">
              Unit Ids for {user?.email}
            </label>
            <input
              name="unitNos"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[0-9]*(,[0-9]*)*$/i,
                  message: (
                    <ErrorText>
                      You need to specify unit IDs in a comma separated list
                      with no spaces. <br />
                      E.G. 123,1234,12345
                    </ErrorText>
                  ),
                },
              })}
              aria-label="unitIds"
              className="p-2 border border-gray-200 border-rounded"
              placeholder="123479,1234789,432123"
            />
            {errors.unitNos && errors.unitNos.message}
          </div>
          <button
            type="submit"
            className="px-4 py-2 font-medium text-white rounded-lg bg-supernova"
          >
            Upgrade To Leader
          </button>
          <div className="arrow" ref={setArrowElement} style={styles.arrow} />
        </form>
      )}
    </div>
  );
}

export function Payers() {
  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) =>
          row.canExpand ? (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
            </span>
          ) : null,
      },
      {
        Header: "Name",
        accessor: (row) => `${row?.firstName ?? ""} ${row?.lastName ?? ""}`,
      },
      {
        Header: "Email",
        accessor: (row) => row.email,
      },
      {
        Header: "Phone",
        accessor: (row) => {
          return row?.phone;
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          return <UpgradeToLeaderButton user={row?.original?.User} />;
        },
      },
    ],
    []
  );

  const [payers, setPayers] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: payers,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    customFetch(`${API_URL}/payers/subscriptions`).then(({ payers }) =>
      setPayers(payers)
    );
  }, []);

  function renderRowSubComponent({ original }) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div>Next Payment Info</div>
        <div></div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div animate className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Payers</h1>
          <span>List of payers and their subscriptions</span>
        </div>
      </motion.div>
      {payers.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow notHoverable {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    key="globalfilter"
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              {page &&
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    // Use a React.Fragment here so the table markup is still valid
                    <React.Fragment {...row.getRowProps()}>
                      <TableRow
                        onClick={() => {
                          navigate(`payer/${row.original.id}`);
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <TableData {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableData>
                          );
                        })}
                      </TableRow>
                      {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                      {row.isExpanded ? (
                        <TableRow>
                          <TableData colSpan={visibleColumns.length}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            {renderRowSubComponent(row)}
                          </TableData>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
          <motion.div animate className="pt-4 pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
}
