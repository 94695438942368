/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Input } from "./App";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import customFetch, {
  API_URL,
  webTokenKey,
  BASE_API_URL,
  isDev,
} from "./utils/fetch";
import logo from "./assets/logo.png";
import scoutpayLogo from "./assets/scoutpaylogo.png";
import { PROJECT_ID } from "./utils/constants";
import Checkmark from "./assets/checkmark";
import jwt from "jsonwebtoken";
import Cleave from "cleave.js/react";
import { DateTime } from "luxon";
import { useRef } from "react";
import LogRocket from "logrocket";
import { X } from "heroicons-react";
import { navigate } from "hookrouter";
import { Header } from "./Checkout";

function ErrorText({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="text-red-400 text-sm pl-2 mb-3"
    >
      {children}
    </motion.div>
  );
}

function LoginForm({ setUserToken, setShowLoginForm }) {
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(false);

  function onSubmit({ loginEmail: email, loginPassword: password }) {
    fetch(`${BASE_API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (resp) => {
      if (resp.status === 400) {
        return setError(true);
      }
      const json = await resp.json();
      localStorage.setItem(webTokenKey, json.jwtToken);
      setUserToken(json.jwtToken);
      setShowLoginForm(false);
    });
  }

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-75 flex justify-center items-center z-30">
      <div className="max-w-screen-sm w-full bg-yellow-200 rounded p-8">
        <div className="w-full flex justify-end pb-4">
          <X
            className="cursor-pointer"
            onClick={() => setShowLoginForm(false)}
          />
        </div>
        {error && (
          <div className="w-full border border-red-600 border-solid p-2 mb-4 rounded">
            Your username or password is incorrect
          </div>
        )}
        <h3 className="pb-4 text-2xl font-medium">Login</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Input
              name="loginEmail"
              placeholder="Email Address"
              hasError={errors.loginEmail}
              passedRef={register({
                required: {
                  value: true,
                  message: <ErrorText>Email address is required</ErrorText>,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: <ErrorText>Invalid email address</ErrorText>,
                },
              })}
            />
            {errors.loginEmail && errors.loginEmail.message}
          </div>
          <div className="">
            <Input
              name="loginPassword"
              type="password"
              placeholder="Password"
              hasError={errors.loginPassword}
              passedRef={register({
                required: true,
              })}
            />
            {errors.loginPassword && (
              <ErrorText>Password is required</ErrorText>
            )}
          </div>
          <button
            type="submit"
            className="p-2 mr-2 rounded-lg text-center text-supernova bg-darkfly"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

function CheckoutForm({
  register,
  errors,
  isLoggedIn,
  units,
  userToken,
  setUserToken,
  troopNumber,
  setShowLoginForm,
  setUnitId,
  unitId,
}) {
  function renderYourInfo() {
    if (isLoggedIn) {
      return (
        <>
          <h1 className="text-2xl font-semibold pb-6">Logged In As</h1>
          <div>
            <div className="flex">
              <div>{jwt.decode(userToken).email}</div>
              <div className="px-4">|</div>
              <div
                onClick={() => {
                  localStorage.removeItem("login-token");
                  setUserToken(null);
                }}
                className="cursor-pointer text-blue-600 hover:underline"
              >
                Logout
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <h1 className="text-2xl font-semibold pb-6">Payer Information</h1>
        <div className="flex">
          <div className="w-1/2 pr-4">
            <Input
              name="payerFirstName"
              hasError={errors.payerFirstName}
              placeholder="First Name"
              passedRef={register({ required: true })}
            />
            {errors.payerFirstName && (
              <ErrorText>This field is required</ErrorText>
            )}
          </div>
          <div className="w-1/2">
            <Input
              name="payerLastName"
              hasError={errors.payerLastName}
              placeholder="Last Name"
              passedRef={register({ required: true })}
            />
            {errors.payerLastName && (
              <ErrorText>This field is required</ErrorText>
            )}
          </div>
        </div>
        <div className="">
          <Input
            name="email"
            placeholder="Email Address"
            hasError={errors.email}
            passedRef={register({
              required: {
                value: true,
                message: <ErrorText>Email address is required</ErrorText>,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: <ErrorText>Invalid email address</ErrorText>,
              },
            })}
          />
          {errors.email && errors.email.message}
        </div>
        <div className="">
          <Input
            name="password"
            type="password"
            placeholder="Password"
            hasError={errors.password}
            passedRef={register({
              required: true,
            })}
          />
          {errors.password && <ErrorText>Password is required</ErrorText>}
        </div>
        <div></div>
        <div className="">
          <Cleave
            name="phone"
            placeholder="Phone Number"
            className="rounded-lg h-12 p-2 px-3 w-full placeholder-gray-400"
            htmlRef={register({
              required: true,
            })}
            options={{
              blocks: [3, 3, 4],
              delimiter: "-",
              numericOnly: true,
            }}
          />
          {errors.phone && <ErrorText>Phone Number is required</ErrorText>}
        </div>
      </>
    );
  }

  const unit = units.filter((unit) => unit.unitId === unitId?.toString?.());

  return (
    <motion.div
      key="checkout-form"
      initial={{ opacity: 0, x: 0 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.15 }}
      exit={{ opacity: 0, x: -150 }}
      className={`w-full`}
    >
      <h1 className="text-2xl font-semibold pb-6">Leader Information</h1>
      <div className="flex">
        <div className="w-1/2 pr-4">
          <Input
            name="firstName"
            hasError={errors.firstName}
            placeholder="First Name"
            passedRef={register({ required: true })}
          />
          {errors.firstName && <ErrorText>This field is required</ErrorText>}
        </div>
        <div className="w-1/2">
          <Input
            name="lastName"
            hasError={errors.lastName}
            placeholder="Last Name"
            passedRef={register({ required: true })}
          />
          {errors.lastName && <ErrorText>This field is required</ErrorText>}
        </div>
      </div>
      <div className="flex flex-col pb-4">
        <div className="w-full">
          <Input
            name="troopNumber"
            type="number"
            disabled
            hasError={true}
            onChange={(e) => {
              setUnitId(e.target.value);
            }}
            placeholder="Unit ID"
            passedRef={register}
          />
        </div>
        {unit.length > 0 && (
          <div className="flex w-full pt-4 justify-between">
            <div className="flex flex-col">
              <div className="opacity-75">Unit Type</div>
              <div>{unit[0].type}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Unit Number</div>
              <div>{unit[0].no}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Sponsoring Org</div>
              <div>{unit[0].sponsoringOrg}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">City</div>
              <div>{unit[0].city}</div>
            </div>
            <div className="flex flex-col">
              <div className="opacity-75">Distict Name</div>
              <div>{unit[0].districtName}</div>
            </div>
          </div>
        )}
      </div>
      {renderYourInfo()}

      <div className="flex mt-8 items-center">
        {isLoggedIn ? (
          <button
            type="submit"
            className="px-4 py-2 mr-2 rounded-lg text-center text-supernova bg-darkfly"
          >
            Checkout
          </button>
        ) : (
          <>
            <button
              type="submit"
              className="px-4 py-2 mr-2 rounded-lg text-center text-supernova bg-darkfly"
            >
              Checkout and Register
            </button>
            <div>
              or{" "}
              <span
                onClick={() => setShowLoginForm(true)}
                className="cursor-pointer text-blue-600 hover:underline"
              >
                Sign In
              </span>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default function LeaderCheckout({ setOrderId, troopNumber }) {
  const PLANS = { MONTHLY: 1, YEARLY: 2 };
  const [showCheckoutIframe, setShowIframe] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [order, _setOrder] = useState({});
  const [cards, setCards] = useState();
  const [plan, setPlan] = useState();
  const [memberType, setMemberType] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [donePaying, setDonePaying] = useState();
  const [payError, setPayError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const isMonthly = selectedPlan === PLANS.MONTHLY;
  const [userToken, setUserToken] = useState(localStorage.getItem(webTokenKey));
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [units, setUnits] = useState();
  const [magazineAddon, setMagazineAddon] = useState(false);
  const isLoggedIn = jwt.decode(userToken)?.exp > new Date().getTime() / 1000;
  const [unitId, _setUnitId] = useState(troopNumber);
  const [scout, _setScout] = useState();
  const orderRef = useRef(order);
  const scoutRef = useRef(scout);
  const unitIdRef = useRef(unitId);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      troopNumber,
    },
  });

  const setOrder = (order) => {
    orderRef.current = order;
    _setOrder(order);
  };

  const setScout = (scout) => {
    scoutRef.current = scout;
    _setScout(scout);
  };

  const setUnitId = (unit) => {
    unitIdRef.current = unit;
    _setUnitId(unit);
  };

  const onSubmit = (data) => {
    const {
      email,
      password,
      firstName,
      lastName,
      troopNumber,
      phone,
      payerFirstName,
      payerLastName,
    } = data;
    customFetch(`${API_URL}/orders/boyscouts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: PROJECT_ID,
        plan,
        isMonthly,
        memberType,
        unitId: troopNumber,
        magazineAddon,
        isLeader: true,
        payer: {
          firstName: payerFirstName,
          lastName: payerLastName,
          email,
          password,
          phone,
        },
        scout: {
          firstName,
          lastName,
        },
      }),
    }).then((resp) => {
      if (resp._status === 400) {
        setError("email", "manual", <ErrorText>{resp.message}</ErrorText>);
        return;
      }
      if (resp._status === 500) {
        setServerError(true);
        return;
      }
      setOrder(resp.order);
      setCards(resp.tokenizedCards);
      setScout(resp.scout);
      if (resp.jwtToken) {
        localStorage.setItem(webTokenKey, resp.jwtToken);
        setUserToken(resp.jwtToken);
      }
      setShowCheckout(true);
      if (email) {
        setShowIframe(true);
      }
    });
  };

  const handleTokenizedSubmit = (e) => {
    e.preventDefault();
    customFetch(`${API_URL}/orders/${order?.id}/closeTokenizeAndPayUpFront`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tokenizedCardId: selectedCard?.id,
      }),
    }).then(() => {
      setDonePaying(true);
    });
  };

  function updateOrderStatusToClosed(orderId, worldpayReturn, orderDetails) {
    if (orderId) {
      fetch(`${API_URL}/orders/${orderId}/closeTokenizeAndPayUpFront`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          worldpayReturn,
          orderDetails,
          card: {
            lastFour: worldpayReturn.LastFour,
            cardType: worldpayReturn.CardLogo,
          },
        }),
      });
    }
  }

  useEffect(() => {
    const dataListener = (event) => {
      if (typeof event.data === "string") {
        const eventData = JSON.parse(event?.data);
        // Response from child iframe
        if (eventData?.message === "worldpay-transaction" && order?.id !== "") {
          if (
            eventData?.serializedParams?.ExpressResponseMessage === "Approved"
          ) {
            updateOrderStatusToClosed(
              orderRef.current?.id,
              eventData?.serializedParams
            );
          } else {
            setPayError(true);
          }
          setDonePaying(true);
        }
      }
    };

    window.addEventListener("message", dataListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isDev && LogRocket.init("mshgpb/scoutpay");
    customFetch(`${API_URL}/units/project/${PROJECT_ID}`).then(({ units }) => {
      setUnits(units);
      const unitId = units.filter(
        (unit) => unit.unitId === Number.parseInt(troopNumber)
      )[0];
      setValue("unitId", unitId?.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userToken && !isDev) {
      LogRocket.identify(jwt.decode(userToken).id, {
        email: jwt.decode(userToken).email,
      });
    }
  }, [userToken]);

  useEffect(() => {
    if (memberType) {
      customFetch(
        `${API_URL}/subscriptionplans/leaders/${PROJECT_ID}`,
        null,
        false
      ).then(({ subscriptionPlans }) => {
        let plan;
        if (memberType === "new") {
          plan = subscriptionPlans.filter((pl) => !pl.forExistingMembers);
        } else {
          plan = subscriptionPlans.filter((pl) => pl.forExistingMembers);
        }
        setPlan(plan?.[0]);
      });
    }
  }, [memberType]);

  useEffect(() => {
    if (order?.transactionSetupId && cards && cards?.length === 0) {
      setShowIframe(true);
    }
    if (order?.id) {
      setOrderId(order?.id);
    }
  }, [order, cards, setOrderId]);

  const cartTotal = order?.upfrontAmount;

  return units && plan && memberType ? (
    <div className="w-full">
      <Header
        isLoggedIn={isLoggedIn}
        userToken={userToken}
        setUserToken={setUserToken}
        setShowLoginForm={setShowLoginForm}
      />
      {showLoginForm && (
        <LoginForm
          setUserToken={setUserToken}
          setShowLoginForm={setShowLoginForm}
        />
      )}
      <div className="flex flex-col items-center w-full">
        <div className="flex py-10">
          <img src={scoutpayLogo} className="w-64" alt="logo" />
        </div>
        {serverError && (
          <div className="flex flex-col py-8">
            <div className="text-lg font-semibold pb-6">
              There was an issue with the server.
            </div>
            <div className="flex flex-col">
              <button
                className="mt-6 font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                Try Again
              </button>
            </div>
          </div>
        )}
        {!serverError && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col max-w-screen-md w-full mx-auto py-4 px-4"
          >
            <motion.div
              animate
              className="flex flex-col text-xl py-6 px-10 rounded-lg mb-4 bg-darkfly text-supernova font-medium"
            >
              <AnimatePresence exitBeforeEnter>
                {showCheckout ? (
                  <motion.div
                    key="checkout-selected"
                    initial={{ opacity: 0, width: "50%" }}
                    animate={{ opacity: 1, width: "100%" }}
                    exit={{ opacity: 0 }}
                    transition={{
                      type: "spring",
                      mass: 0.2,
                      tension: 182,
                    }}
                  >
                    <motion.div
                      className={`w-full mr-8 bg-pampas text-darkfly ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                    >
                      <motion.div>
                        Pay Per {isMonthly ? "Month" : "Year"}
                      </motion.div>
                      <motion.div>
                        <motion.span className="text-4xl font-bold">
                          $
                          {Number.parseFloat(
                            isMonthly ? plan?.monthlyFee : plan?.yearlyFee
                          ).toFixed(2)}
                        </motion.span>
                        /{isMonthly ? "month" : "year"}
                        {isMonthly && (
                          <>
                            <motion.div>
                              after $
                              {Number.parseFloat(plan?.upfrontMonthly).toFixed(
                                2
                              )}{" "}
                              up front
                            </motion.div>
                            <motion.div>
                              charges through{" "}
                              {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                                "MMMM y"
                              )}
                            </motion.div>
                          </>
                        )}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="checkout-unselected"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <motion.div className="text-3xl pl-2">
                      Select Plan
                    </motion.div>
                    <motion.div className="border-t opacity-50 my-2 mb-8 border-supernova w-64" />
                    <motion.div className="flex flex-col items-start sm:flex-row w-full justify-between">
                      <motion.div
                        onClick={() => setSelectedPlan(PLANS.MONTHLY)}
                        className={`w-full sm:w-1/2 mb-4 mr-8 hover:bg-pampas hover:text-darkfly ${
                          selectedPlan === PLANS.MONTHLY &&
                          "bg-pampas text-darkfly"
                        } ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                      >
                        <motion.div>Pay Per Month</motion.div>
                        <motion.div>
                          <motion.span className="text-4xl font-bold">
                            ${Number.parseFloat(plan?.monthlyFee).toFixed(2)}
                          </motion.span>
                          /month
                          <motion.div>
                            after $
                            {Number.parseFloat(plan?.upfrontMonthly).toFixed(2)}{" "}
                            up front
                          </motion.div>
                          <motion.div className="text-sm">
                            charges through{" "}
                            {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                              "MMMM y"
                            )}
                          </motion.div>
                        </motion.div>
                      </motion.div>
                      <motion.div
                        onClick={() => setSelectedPlan(PLANS.YEARLY)}
                        className={`w-full h-full sm:w-1/2 mb-4 mr-8 hover:bg-pampas hover:text-darkfly ${
                          selectedPlan === PLANS.YEARLY &&
                          "bg-pampas text-darkfly"
                        } ease-in transition duration-75 cursor-pointer p-2 rounded-lg shadow-sm`}
                      >
                        <motion.div>Pay Per Year</motion.div>
                        <motion.div>
                          <motion.span className="text-4xl font-bold">
                            ${Number.parseFloat(plan?.yearlyFee).toFixed(2)}
                          </motion.span>
                          /year
                          <motion.div className="text-sm">
                            charges through{" "}
                            {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                              "MMMM y"
                            )}
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="border-t opacity-50 my-2 mt-10 border-supernova w-full" />
              <div className="flex text-base w-full">
                <div className="w-1/2">
                  <div>Michigan Crossroads Council</div>
                </div>
                <div className="w-1/2 text-right">
                  For {memberType === "new" ? "new" : "existing"} leaders
                </div>
              </div>
            </motion.div>
            <div className="flex">
              <AnimatePresence exitBeforeEnter>
                {showCheckout ? (
                  <motion.div
                    key="iframe-checkout"
                    initial={{ opacity: 0, x: 150 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.15 }}
                    exit={{ opacity: 0, x: -150 }}
                    className="w-full"
                  >
                    <h1 className="text-2xl font-semibold pb-6 pt-6">
                      Payment
                    </h1>
                    {donePaying && !payError ? (
                      <div className="flex flex-col">
                        <div className="text-lg font-semibold pb-6">
                          Successfully paid!
                        </div>
                        <div className="flex flex-col">
                          <div className="text-lg font-semibold pb-2">
                            Order Details:
                          </div>
                          <div className="flex py-1">
                            <span className="w-48">
                              Upfront Membership Fee:
                            </span>{" "}
                            $
                            {Number.parseFloat(order?.upfrontAmount).toFixed(2)}
                          </div>
                          {order?.subscription?.occurence === "MONTHLY" && (
                            <div className="flex py-1">
                              <span className="w-48">Membership Fee:</span> $
                              {Number.parseFloat(
                                order?.subscriptionAmount
                              ).toFixed(2)}
                              /month through{" "}
                              {DateTime.fromISO(plan?.subscriptionEnd).toFormat(
                                "MMMM y"
                              )}
                            </div>
                          )}
                          <div className="flex font-semibold  pt-2">
                            <span className="w-48">Total Paid Today:</span> $
                            {Number.parseFloat(cartTotal).toFixed(2)}
                          </div>
                          <button
                            className="mt-6 font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.reload();
                            }}
                          >
                            Pay for another Leader
                          </button>
                        </div>
                      </div>
                    ) : donePaying && payError ? (
                      <div className="flex flex-col">
                        <div className="text-lg font-semibold pb-6">
                          There was an issue with your card.
                        </div>
                        <div className="flex flex-col">
                          <button
                            className="mt-6 font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.reload();
                            }}
                          >
                            Try Again
                          </button>
                        </div>
                      </div>
                    ) : (
                      !showCheckoutIframe &&
                      !donePaying && (
                        <div className="flex flex-col">
                          <div className="text-lg font-semibold pb-6">
                            Processing Your Transaction
                          </div>
                        </div>
                      )
                    )}
                    {!showCheckoutIframe && !donePaying && cards?.length > 0 && (
                      <div>
                        {cards.map((card) => {
                          const selected = selectedCard?.id === card?.id;

                          return (
                            <div
                              onClick={() => setSelectedCard(card)}
                              className={`w-full cursor-pointer p-2 px-4 mb-4 bg-gray-200 rounded border ${
                                selected
                                  ? "border-green-400"
                                  : "border-gray-400"
                              } flex h-full items-center`}
                            >
                              <div className="flex align-middle pr-4">
                                <div
                                  className={`rounded-full w-6 h-6 border ${
                                    selected
                                      ? "border-green-600 bg-green-300"
                                      : "border-gray-400 bg-gray-300"
                                  } flex items-center justify-center`}
                                >
                                  <Checkmark className="w-3 h-3 text-white" />
                                </div>
                              </div>
                              <div className="pt-2">
                                <div className="text-sm uppercase">
                                  {card?.cardType}
                                </div>
                                <div style={{ verticalAlign: "sub" }}>
                                  <span
                                    style={{
                                      lineHeight: "30px",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    **** **** ****
                                  </span>{" "}
                                  {card?.lastFour}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className="flex justify-between pt-6">
                          <div>
                            {selectedCard && (
                              <button
                                onClick={handleTokenizedSubmit}
                                className="font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
                              >
                                Pay with {selectedCard?.lastFour}
                              </button>
                            )}
                            <button
                              className="font-medium text-white bg-supernova hover:bg-yellowsea rounded-lg py-2 px-4 mr-4"
                              onClick={() => setShowIframe(true)}
                            >
                              Pay with new card
                            </button>
                          </div>
                          <div animate className="text-2xl font-semibold">
                            Total: ${Number.parseFloat(cartTotal).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    )}
                    {showCheckoutIframe && !donePaying && (
                      <>
                        <div className="text-2xl font-semibold">
                          Total: ${Number.parseFloat(cartTotal).toFixed(2)}
                        </div>
                        <iframe
                          title="Checkout Form"
                          className={`w-full overflow-hidden overflow-y-hidden overflow-x-hidden`}
                          style={{ height: "30rem" }}
                          src={`https://${
                            isDev ? "certtransaction" : "transaction"
                          }.hostedpayments.com/?TransactionSetupID=${
                            order?.transactionSetupId
                          }`}
                        />
                      </>
                    )}
                  </motion.div>
                ) : (
                  selectedPlan && (
                    <CheckoutForm
                      userToken={userToken}
                      units={units}
                      getValues={getValues}
                      setUserToken={setUserToken}
                      isLoggedIn={isLoggedIn}
                      setShowLoginForm={setShowLoginForm}
                      magazineAddon={magazineAddon}
                      setMagazineAddon={setMagazineAddon}
                      troopNumber={troopNumber}
                      setUnitId={setUnitId}
                      unitId={unitId}
                      register={register}
                      errors={errors}
                    />
                  )
                )}
              </AnimatePresence>
            </div>
          </form>
        )}
        <div>
          <img src={logo} className="w-32" alt="logo" />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full">
      <Header
        isLoggedIn={isLoggedIn}
        userToken={userToken}
        setUserToken={setUserToken}
        setShowLoginForm={setShowLoginForm}
      />
      {showLoginForm && (
        <LoginForm
          setUserToken={setUserToken}
          setShowLoginForm={setShowLoginForm}
        />
      )}
      <div className="flex flex-col items-center w-full">
        <div className="flex py-10">
          <img src={scoutpayLogo} className="w-64" alt="logo" />
        </div>
        <motion.div
          animate
          className="flex flex-col text-xl py-6 px-10 rounded-lg mb-4 mt-4 bg-darkfly text-supernova font-medium"
        >
          <h1 className="pb-8 text-2xl">
            Are you paying for a new leader or an existing leader?
          </h1>
          <motion.div className="flex w-full justify-between pb-8 flex-col sm:flex-row items-center">
            <motion.div
              onClick={() => setMemberType("new")}
              className={`w-full sm:w-1/2 mr-8 mb-4 text-center hover:bg-pampas hover:text-darkfly ease-in transition duration-75 cursor-pointer p-8 rounded-lg shadow border-r-2 border-t border-l border-b-2 border-opacity-75 border-black`}
            >
              New Leader
            </motion.div>
            <motion.div
              onClick={() => setMemberType("existing")}
              className={`w-full sm:w-1/2 mr-8 mb-4 text-center hover:bg-pampas hover:text-darkfly ease-in transition duration-75 cursor-pointer p-8 rounded-lg shadow border-r-2 border-t border-l border-b-2 border-opacity-75 border-black`}
            >
              Existing Leader
            </motion.div>
          </motion.div>
        </motion.div>
        <div>
          <button
            onClick={() => navigate(`/checkout/${troopNumber}`)}
            className="text-blue-500 underline pb-8 hover:cursor-pointer"
          >
            Pay for a member instead?
          </button>
        </div>
        <div>
          <img src={logo} className="w-32" alt="logo" />
        </div>
      </div>
    </div>
  );
}
