/* eslint-disable no-fallthrough */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Tabs } from "./tabs";
import { Controller, useForm } from "react-hook-form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import customFetch, { API_URL } from "../../utils/fetch";
import { DateTime } from "luxon";

const PlanTypes = {
  NewScout: "New Scout",
  ExistingScout: "Existing Scout",
  NewLeader: "New Leader",
  ExistingLeader: "Existing Leader",
};

function Plan({ register, title, type }) {
  return (
    <div className="mt-3 sm:mt-5">
      <div>
        <h3 className="pb-2 font-medium leading-6 text-gray-900 text-md">
          {title}
        </h3>
      </div>
      <div className="mt-2">
        <label
          htmlFor="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          Plan Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name={`${type}_name`}
            id="name"
            ref={register({ required: `${type} name is required` })}
            autoComplete="given-name"
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-supernova focus:border-supernova sm:text-sm"
          />
        </div>
      </div>

      <div className="flex mt-2 mb-4">
        <div className="mr-2">
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            Yearly Fee
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name={`${type}_yearly_fee`}
              ref={register({ required: `${type} yearly fee is required` })}
              id="price"
              className="block w-full pr-12 border-gray-300 rounded-md focus:ring-supernova focus:border-supernova pl-7 sm:text-sm"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        <div className="mr-2">
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            Upfront Monthly Fee
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name={`${type}_upfront_monthly_fee`}
              ref={register({
                required: `${type} upfront monthly fee is required`,
              })}
              id="price"
              className="block w-full pr-12 border-gray-300 rounded-md focus:ring-supernova focus:border-supernova pl-7 sm:text-sm"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700"
          >
            Monthly Fee
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name={`${type}_monthly_fee`}
              ref={register({ required: `${type} monthly fee is required` })}
              id="price"
              className="block w-full pr-12 border-gray-300 rounded-md focus:ring-supernova focus:border-supernova pl-7 sm:text-sm"
              placeholder="0.00"
              aria-describedby="price-currency"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PlanDialogue({
  plans = undefined,
  isOpen = false,
  setIsOpen,
  onCreatedPlan,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { control, register, handleSubmit, errors } = useForm({
    validateCriteriaMode: "all",
  });
  const [isDelayed, setIsDelayed] = useState(false);

  const tabs = [
    {
      name: "Scouts",
      content: (
        <div className="">
          <Plan
            register={register}
            title="New Scout"
            type={PlanTypes.NewScout}
          />
          <Plan
            register={register}
            title="Existing Scout"
            type={PlanTypes.ExistingScout}
          />
        </div>
      ),
    },
    {
      name: "Leaders",
      content: (
        <div className="">
          <Plan
            register={register}
            title="New Leader"
            type={PlanTypes.NewLeader}
          />
          <Plan
            register={register}
            title="Existing Leader"
            type={PlanTypes.ExistingLeader}
          />
        </div>
      ),
    },
  ];

  async function deletePlans(planIds) {
    const ids = planIds.filter((id) => id !== undefined);
    setLoading(true);
    try {
      for (let planId of ids) {
        await customFetch(`${API_URL}/subscriptionplans/${planId}`, {
          method: "DELETE",
        });
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  async function onSubmit(data) {
    if (loading) return;

    const subTime = {
      effectiveStart: DateTime.fromJSDate(data.planDate[0])
        .toISO()
        .slice(0, 10),
      effectiveEnd: DateTime.fromJSDate(data.planDate[1]).toISO().slice(0, 10),
      subscriptionStart: data["delayed_date"]
        ? DateTime.fromJSDate(data["delayed_date"]).toISO().slice(0, 10)
        : undefined,
      subscriptionEnd: DateTime.fromJSDate(data["subscription_end_date"])
        .toISO()
        .slice(0, 10),
    };

    setError();

    const newScoutInput = {
      ...subTime,
      forExistingMembers: false,
      forLeaders: false,
      name: data[`${PlanTypes.NewScout}_name`],
      yearlyFee: data[`${PlanTypes.NewScout}_yearly_fee`],
      upfrontMonthly: data[`${PlanTypes.NewScout}_upfront_monthly_fee`],
      monthlyFee: data[`${PlanTypes.NewScout}_monthly_fee`],
    };
    const existingScoutInput = {
      ...subTime,
      forExistingMembers: true,
      forLeaders: false,
      name: data[`${PlanTypes.ExistingScout}_name`],
      yearlyFee: data[`${PlanTypes.ExistingScout}_yearly_fee`],
      upfrontMonthly: data[`${PlanTypes.ExistingScout}_upfront_monthly_fee`],
      monthlyFee: data[`${PlanTypes.ExistingScout}_monthly_fee`],
    };
    const newLeaderInput = {
      ...subTime,
      forExistingMembers: false,
      forLeaders: true,
      name: data[`${PlanTypes.NewLeader}_name`],
      yearlyFee: data[`${PlanTypes.NewLeader}_yearly_fee`],
      upfrontMonthly: data[`${PlanTypes.NewLeader}_upfront_monthly_fee`],
      monthlyFee: data[`${PlanTypes.NewLeader}_monthly_fee`],
    };
    const existingLeaderInput = {
      ...subTime,
      forExistingMembers: true,
      forLeaders: true,
      name: data[`${PlanTypes.ExistingLeader}_name`],
      yearlyFee: data[`${PlanTypes.ExistingLeader}_yearly_fee`],
      upfrontMonthly: data[`${PlanTypes.ExistingLeader}_upfront_monthly_fee`],
      monthlyFee: data[`${PlanTypes.ExistingLeader}_monthly_fee`],
    };

    setLoading(true);

    // Create new scout
    const newScoutPlan = await customFetch(
      `${API_URL}/subscriptionplans/ckddvonyn01948unwfqshogjo`,
      {
        method: "POST",
        body: JSON.stringify(newScoutInput),
      }
    );
    const existingScoutPlan = await customFetch(
      `${API_URL}/subscriptionplans/ckddvonyn01948unwfqshogjo`,
      {
        method: "POST",
        body: JSON.stringify(existingScoutInput),
      }
    );
    const newLeaderPlan = await customFetch(
      `${API_URL}/subscriptionplans/ckddvonyn01948unwfqshogjo`,
      {
        method: "POST",
        body: JSON.stringify(newLeaderInput),
      }
    );

    const existingLeaderPlan = await customFetch(
      `${API_URL}/subscriptionplans/ckddvonyn01948unwfqshogjo`,
      {
        method: "POST",
        body: JSON.stringify(existingLeaderInput),
      }
    );

    console.log(
      newScoutPlan,
      existingScoutPlan,
      newLeaderPlan,
      existingLeaderPlan
    );

    let planErrorNames = [];

    // eslint-disable-next-line default-case
    switch (400) {
      case newScoutPlan._status:
        planErrorNames.push("New Scout");
      case existingScoutPlan._status:
        planErrorNames.push("Existing Scout");
      case newLeaderPlan._status:
        planErrorNames.push("New Leader");
      case existingLeaderPlan._status:
        planErrorNames.push("Existing Leader");
    }

    if (planErrorNames.length > 0) {
      setError(
        `${planErrorNames.join(
          ", "
        )} plans already exist either in name or in the same billing window`
      );

      deletePlans([
        newScoutPlan?.plan?.id,
        existingScoutPlan?.plan?.id,
        newLeaderPlan?.plan?.id,
        existingLeaderPlan?.plan?.id,
      ]);
      setLoading(false);
      return;
    }

    // Generic catch all
    if (
      newScoutPlan._status !== 200 &&
      existingScoutPlan._status !== 200 &&
      newLeaderPlan._status !== 200 &&
      existingLeaderPlan._status !== 200
    ) {
      deletePlans([
        newScoutPlan?.plan?.id,
        existingScoutPlan?.plan?.id,
        newLeaderPlan?.plan?.id,
        existingLeaderPlan?.plan?.id,
      ]);
      setError("There was an unexpected error please try again later.");
      setLoading(false);
      return;
    }

    onCreatedPlan();
    setLoading(false);
    setIsOpen(false);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setIsOpen}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                {error && (
                  <div className="w-full p-2 mb-6 bg-red-100 border border-red-200 rounded">
                    {error}
                  </div>
                )}
                {Object.keys(errors).length > 0 && (
                  <div className="w-full p-2 mb-6 bg-red-100 border border-red-200 rounded">
                    {Object.keys(errors).map((error) => (
                      <div className="text-sm">{errors[error].message}</div>
                    ))}
                  </div>
                )}
                <div className="mb-8">
                  <div className="flex">
                    <div>
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Plan Effective Date Window
                      </label>
                      <span className="block w-64 text-xs leading-snug text-gray-500">
                        Make sure this does not overlap with any other plans.
                      </span>
                      <div className="mt-2">
                        <Controller
                          control={control}
                          name="planDate"
                          as={DateRangePicker}
                          minDate={new Date()}
                          rules={{ required: "Plan dates are required" }}
                          valueName="value"
                          className="block border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="ml-2">
                      <label
                        htmlFor="price"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Subscription End
                      </label>
                      <span className="block text-xs leading-snug text-gray-500">
                        When the subscription should stop creating new payments.
                      </span>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <Controller
                          control={control}
                          rules={{
                            required: "Subscription end date is required",
                          }}
                          name={`subscription_end_date`}
                          calendarClassName="z-20"
                          as={DatePicker}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative flex items-start mt-8">
                    <div className="flex items-center h-5">
                      <input
                        id={`delayed`}
                        aria-describedby="comments-description"
                        name="comments"
                        value={isDelayed}
                        onChange={(e) => setIsDelayed(e.target.checked)}
                        type="checkbox"
                        className="w-4 h-4 border-gray-300 rounded text-supernova focus:ring-supernova"
                      />
                    </div>
                    <div className="w-64 ml-3 text-sm">
                      <label
                        htmlFor={`delayed`}
                        className="font-medium text-gray-700"
                      >
                        Delayed monthly subscription start date
                      </label>
                      <p id="comments-description" className="text-gray-500">
                        Does the monthly subscription need to be charged later?
                      </p>
                    </div>
                    {isDelayed && (
                      <Controller
                        control={control}
                        name={`delayed_date`}
                        calendarClassName="z-20"
                        as={DatePicker}
                      />
                    )}
                  </div>
                </div>
                <Tabs tabs={tabs} />
              </form>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className={`${
                    loading ? "disabled opacity-80 cursor-not-allowed" : ""
                  } inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-supernova hover:bg-supernova focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm`}
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? "Creating Plans..." : "Create Plan"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
